import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Box,
  CssBaseline,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { useNavigate, useLocation } from "react-router-dom";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MapIcon from '@mui/icons-material/Map';
import PublicIcon from '@mui/icons-material/Public';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FlightIcon from '@mui/icons-material/Flight';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications"; // Import Notifications Icon

import logo from "../images/FullLogo.png";
import { LogoutButton } from "./buttons/logout-button"; // Use this inside the dropdown
import PermissionProvider from "src/authorization/auth-provider";
import { AUTHORIZATION_GEOZONES_READ, AUTHORIZATION_USER_READ, AUTHORIZATION_EQUIPMENT_READ, AUTHORIZATION_FLIGHTLOG_READ, AUTHORIZATION_ORGANISATION_READ, Permission, AUTHORIZATION_FLIGHT_AUTH_READ } from "../authorization/auth-types";
import { useAuth0 } from "@auth0/auth0-react";
import RequirePermission from "src/authorization/require-permission";
import { jwtDecode } from "jwt-decode";
import { Logout } from "@mui/icons-material";

interface Props {
  children: JSX.Element;
}

type DecodedToken = {
  aud: string[];
  azp: string;
  exp: number;
  iat: number;
  iss: string;
  permissions: string[];
  scope: string;
  sub: string;
};

export const LoggedInPageLayout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedIndex, setSelectedIndex] = React.useState(7);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // For the dropdown menu

  const { user, getAccessTokenSilently, logout } = useAuth0();
  const fetchPermission =
    () =>
    async (permission: Permission): Promise<boolean> => {
      return new Promise<boolean>((resolve, reject) => {
        getAccessTokenSilently()
          .then((claims) => {
            const decoded: DecodedToken = jwtDecode(claims);
            const result = decoded.permissions.includes(permission);
            resolve(result);
          })
          .catch((err) => {
            console.debug("Error obtaining access token", err);
            reject();
          });
      });
    };

  // Update selectedIndex based on current path
  React.useEffect(() => {
    switch (location.pathname) {
      case "/profile":
        setSelectedIndex(0);
        break;
      case "/realtime-map":
        setSelectedIndex(1);
        break;
      case "/maps":
        setSelectedIndex(2);
        break;
      case "/organisation":
        setSelectedIndex(3);
        break;
      case "/drones":
        setSelectedIndex(4);
        break;
      case "/flight-auths":
        setSelectedIndex(6);
        break;
      case "/flight-auths/create-flight-auths":
        setSelectedIndex(6);
        break;
      default:
        setSelectedIndex(1);
    }
  }, [location.pathname]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    path: string
  ) => {
    setSelectedIndex(index);
    navigate(path);
  };

  // Handle dropdown menu open/close
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <PermissionProvider fetchPermission={fetchPermission()}>
        <CssBaseline />

        <Grid container>
          {/* Top horizontal bar with profile icon */}
          <Grid xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px 20px",
                backgroundColor: "#ffffff",
                borderBottom: "1px solid #ddd",
              }}
            >
              {/* Logo in the top bar */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={logo} width="100" alt="logo" style={{ height: "auto" }} />
              </Box>

              {/* Icons Container */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}> {/* Add gap between icons */}
                {/* Notifications Icon */}
                <IconButton>
                  <NotificationsIcon sx={{ color: "darkslategrey" }} /> {/* Dark grey icon */}
                </IconButton>

                {/* Avatar and User Name in the IconButton */}
                <IconButton
                  onClick={handleMenuOpen}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    borderRadius: "8px", // Set rounded rectangle shape
                    "&:focus-visible": { // Only apply styles when the element is truly focused (keyboard/tab focus)
                      outline: "none", // Remove the default browser outline
                      boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.5)", // Custom box shadow focus (you can change the color here)
                      backgroundColor: "rgba(0, 123, 255, 0.1)", // Optional background change on focus
                    },
                    "&:focus": {
                      outline: "none", // Remove the outline if it appears
                    },
                    "&:active": {
                      outline: "none", // Remove outline when clicked
                    },
                  }}
                >
                  <Avatar sx={{ width: 24, height: 24, bgcolor: "darkslategrey" }}>
                    <AccountCircleIcon />
                  </Avatar>
                  <Typography variant="body1" sx={{ color: "darkslategrey" }}>
                    {user?.name}
                  </Typography>
                </IconButton>

              </Box>

              {/* Profile Menu */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ mt: "15px" }}
              >
                <Box sx={{ padding: "10px 20px" }}>
                  <Typography variant="body1">{user?.name}</Typography>
                  <Typography variant="body2">{user?.email}</Typography>
                </Box>
                <Divider />
              
                <MenuItem
                  onClick={() => {
                    logout({ logoutParams: { returnTo: window.location.origin } });
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Grid>

          <Grid xs={12}>
            <Box sx={{ display: "flex"}}>
              <Box>
                <Drawer
                  sx={{
                    width: 250,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: 250,
                      boxSizing: "border-box",
                      backgroundColor: "#f9fafb",
                    },
                  }}
                  variant="permanent"
                  anchor="left"
                >
                  {/* Keep the logo in the Drawer */}
                  <img
                    src={logo}
                    width="185"
                    alt="logo"
                    style={{ padding: "10px 10px 0px 12px" }}
                  />
                  <List>
                    <Typography variant="h6" sx={{ paddingLeft: 2, paddingTop: 2, paddingBottom: 0.3, fontSize: '0.875rem', color: "#575f73" }}>
                      Dashboards
                    </Typography>
                  <RequirePermission to={AUTHORIZATION_GEOZONES_READ}>
                      <ListItem key="realtime-map" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 1}
                          onClick={(event) =>
                            handleListItemClick(event, 1, "/realtime-map")
                          }
                          sx={{ pl: 3 }}
                        >
                          <ListItemIcon sx={{ minWidth: 40, color: "darkslategrey" }}>
                            <PublicIcon/>
                          </ListItemIcon>
                          <ListItemText 
                            primary="Real-Time Map"
                          />
                        </ListItemButton>
                      </ListItem>
                    </RequirePermission>
                    <Typography variant="h6" sx={{ paddingLeft: 2, paddingTop: 2, paddingBottom: 0.3, fontSize: '0.875rem', color: "#575f73" }}>
                      General
                    </Typography>
                    <RequirePermission to={AUTHORIZATION_FLIGHT_AUTH_READ}>
                      <ListItem key="flight-auths" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 6}
                          onClick={(event) =>
                            handleListItemClick(event, 6, "/flight-auths")
                          }
                          sx={{ pl: 3 }}
                        >
                          <ListItemIcon sx={{ minWidth: 40, color: "darkslategrey" }}>
                            <FlightTakeoffIcon />
                          </ListItemIcon>
                          <ListItemText primary="Flight Authorizations" />
                        </ListItemButton>
                      </ListItem>
                    </RequirePermission>
                    <RequirePermission to={AUTHORIZATION_GEOZONES_READ}>
                      <ListItem key="maps" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 2}
                          onClick={(event) =>
                            handleListItemClick(event, 2, "/maps")
                          }
                          sx={{ pl: 3 }}
                        >
                          <ListItemIcon sx={{ minWidth: 40, color: "darkslategrey" }}>
                            <MapIcon />
                          </ListItemIcon>
                          <ListItemText primary="Planning Map" />
                        </ListItemButton>
                      </ListItem>
                    </RequirePermission>
                    <RequirePermission to={AUTHORIZATION_ORGANISATION_READ}>
                      <ListItem key="organisation" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 3}
                          onClick={(event) =>
                            handleListItemClick(event, 3, "/organisation")
                          }
                          sx={{ pl: 3 }}
                        >
                          <ListItemIcon sx={{ minWidth: 40, color: "darkslategrey" }}>
                            <CorporateFareIcon />
                          </ListItemIcon>
                          <ListItemText primary="Organisation" />
                        </ListItemButton>
                      </ListItem>
                    </RequirePermission>

                    <RequirePermission to={AUTHORIZATION_EQUIPMENT_READ}>
                      <ListItem key="drones" disablePadding>
                        <ListItemButton
                          selected={selectedIndex === 4}
                          onClick={(event) =>
                            handleListItemClick(event, 4, "/drones")
                          }
                          sx={{ pl: 3 }}
                        >
                          <ListItemIcon sx={{ minWidth: 40, color: "darkslategrey" }}>
                            <FlightIcon />
                          </ListItemIcon>
                          <ListItemText primary="Drones" />
                        </ListItemButton>
                      </ListItem>
                    </RequirePermission>
                    
                  </List>
                </Drawer>
              </Box>
              <Box>{children}</Box>
            </Box>
          </Grid>
        </Grid>
      </PermissionProvider>
    </div>
  );
};
