export type Permission = string;

export const AUTHORIZATION_GEOZONES_READ : string = "geozones:read";

export const AUTHORIZATION_USER_READ : string = "user:read";

export const AUTHORIZATION_ORGANISATION_READ : string = "organisation:read";

export const AUTHORIZATION_FLIGHTLOG_READ : string = "flights:read";

export const AUTHORIZATION_EQUIPMENT_READ : string = "equipment:read";

export const AUTHORIZATION_REALTIME_MAP_READ : string = "realtime_map:read";

export const AUTHORIZATION_FLIGHT_AUTH_CREATE : string = "create:flight_auth";

export const AUTHORIZATION_FLIGHT_AUTH_READ : string = "read:flight_auth_list";