import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  Grid,
  CircularProgress,
  Alert,
  MenuItem,
  Snackbar,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { LoggedInPageLayout } from "src/components/page-layout";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import mapboxgl from "mapbox-gl";
import { toZonedTime, formatInTimeZone } from "date-fns-tz";
import RequirePermission from "src/authorization/require-permission";
import { AUTHORIZATION_FLIGHT_AUTH_CREATE, AUTHORIZATION_FLIGHT_AUTH_READ } from "src/authorization/auth-types";
import { Config } from "src/config";

mapboxgl.accessToken = Config.mapbox_accesstoken;

interface FlightAuthorizationForm {
  status: string;
  shortName: string;
  timeStart: string;
  timeEnd: string;
  category: string;
  pilot: string;
  pilotId: string;
  client: string;
  drone: string;
  droneModel: string;
  droneRemoteId: string;
  droneWeight: number;
  missionDescription: string;
  importingFile: File | null;
}

const convertToUserTimezone = (utcDate: string | number | Date) => {
  const date = new Date(utcDate);
  return new Intl.DateTimeFormat(undefined, {
    dateStyle: "short",
    timeStyle: "short",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }).format(date);
};

export const CreateFlightAuthorizationPage: React.FC = () => {
  const navigate = useNavigate();
  const { control, handleSubmit, reset, setValue, watch } =
    useForm<FlightAuthorizationForm>({
      defaultValues: {
        status: "ACTIVATED",
        shortName: "",
        timeStart: "",
        timeEnd: "",
        category: "OPEN_CATEGORY_A1",
        pilot: "",
        pilotId: "",
        client: "",
        drone: "",
        droneModel: "",
        droneRemoteId: "",
        droneWeight: 0,
        missionDescription: "",
      },
    });

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [waypoints, setWaypoints] = useState<any[]>([]);
  const [fencePoints, setFencePoints] = useState<any[]>([]);
  const [conflicts, setConflicts] = useState<any[]>([]);

  const selectedFile = watch("importingFile");

  const handleSnackbarClose = () => {
    setError(null);
    setSuccess(null);
  };

  const onUploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("importingFile", file);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(
        Config.api_core_url + "/flight-auths/parseQgcPlanFile",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { waypoints, fencePoints } = response.data;

      setWaypoints(waypoints);
      setFencePoints(fencePoints);
    } catch (err) {
      console.log(err);
      setError("Failed to parse the flight plan file.");
    }
  };

  useEffect(() => {
    const initializeMap = () => {
      const mapInstance = new mapboxgl.Map({
        container: "map", // ID of the container
        style: "mapbox://styles/mapbox/streets-v11",
        center: [0, 0], // Default center
        zoom: 3, // Default zoom
      });

      setMap(mapInstance);

      return () => {
        mapInstance.remove();
      };
    };

    if (!map) initializeMap();
  }, []);

  useEffect(() => {
    if (map && waypoints.length > 0) {
      const firstWaypoint = waypoints[0];
      if (firstWaypoint) {
        map.flyTo({
          center: [firstWaypoint.longitude, firstWaypoint.latitude],
          zoom: 10, // Adjust zoom level as needed
          essential: true, // Ensures the animation is not interrupted
        });
      }
      // Waypoints: Add as dashed blue line
      const waypointCoordinates = waypoints
        .filter((wp: { command: string }) => {
          return (
            wp.command == "MISSION_START" ||
            wp.command == "TAKEOFF" ||
            wp.command == "WAYPOINT" ||
            wp.command == "VTOL_LAND" ||
            wp.command == "LAND" ||
            wp.command == "VTOL_TAKEOFF" ||
            wp.command == "NAV_RALLY_POINT" ||
            wp.command == "RALLY_LAND"
          );
        })
        .map((wp: { longitude: any; latitude: any; command: string }) => [
          wp.longitude,
          wp.latitude,
        ]);

      if (map.getSource(`waypoints`)) {
        // Update existing source if it exists
        (map.getSource(`waypoints`) as mapboxgl.GeoJSONSource).setData({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: waypointCoordinates,
              },
              properties: {},
            },
          ],
        });
      } else {
        // Create new source and layer for waypoints
        map.addSource(`waypoints`, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: waypointCoordinates,
                },
                properties: {},
              },
            ],
          },
        });

        map.addLayer({
          id: `waypoints-layer`,
          type: "line",
          source: `waypoints`,
          layout: { "line-join": "round", "line-cap": "round" },
          paint: {
            "line-color": "#059212",
            "line-width": 2,
            "line-dasharray": [2, 2], // Dashed line style
          },
        });
      }

      // Volume: Add as a polygon layer
      const fenceCoordinates = fencePoints.map(
        (fp: { longitude: any; latitude: any }) => [fp.longitude, fp.latitude]
      );
      if (map.getSource(`fence`)) {
        // Update existing source if it exists
        (map.getSource(`fence`) as mapboxgl.GeoJSONSource).setData({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: { type: "Polygon", coordinates: [fenceCoordinates] },
              properties: {},
            },
          ],
        });
      } else {
        // Create new source and layer for fence polygon
        map.addSource(`fence`, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: { type: "Polygon", coordinates: [fenceCoordinates] },
                properties: {},
              },
            ],
          },
        });

        map.addLayer({
          id: `fence-layer`,
          type: "fill",
          source: `fence`,
          layout: {},
          paint: {
            "fill-color": "#9BEC00",
            "fill-opacity": 0.1,
            "fill-antialias": false,
          },
        });

        // Add the border line layer
        map.addLayer({
          id: `fence-border-layer`,
          type: "line",
          source: `fence`,
          layout: {},
          paint: {
            "line-color": "#059212", // Border color (black in this case)
            "line-width": 1, // Width of the border
          },
        });
      }
    }
  }, [map, waypoints, fencePoints]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setValue("importingFile", file);
      onUploadFile(file);
    }
  };

  const onSubmit = async (data: FlightAuthorizationForm) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert date-time fields to include the user's default timezone
    const timeStartWithZone = formatInTimeZone(
      toZonedTime(new Date(`${data.timeStart}:00`), timezone),
      timezone,
      "yyyy-MM-dd'T'HH:mm:ssXXX"
    );

    const timeEndWithZone = formatInTimeZone(
      toZonedTime(new Date(`${data.timeEnd}:00`), timezone),
      timezone,
      "yyyy-MM-dd'T'HH:mm:ssXXX"
    );

    const updatedData = {
      ...data,
      timeStart: timeStartWithZone,
      timeEnd: timeEndWithZone,
    };

    const formData = new FormData();

    formData.append("flightAuthorization", JSON.stringify(updatedData));
    if (data.importingFile) {
      formData.append("importingFile", data.importingFile);
    }

    try {
      const accessToken = await getAccessTokenSilently();
      await axios.post(Config.api_core_url + "/flight-auths", formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setSuccess("Flight authorization created successfully!");
      setConflicts([]);
      reset();
    } catch (err: any) {
      console.log(err);
      if (
        err.response &&
        err.response.data &&
        err.response.data.type === "FA_COLLISION"
      ) {
        setConflicts(err.response.data.conflictingFlightAuths);
        setError("Conflicting flight authorizations found.");
      } else {
        setError("Failed to create flight authorization.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoggedInPageLayout>
      {/* <RequirePermission
        to={AUTHORIZATION_FLIGHT_AUTH_READ}
        fallback={<div>Unauthorized!</div>}
      > */}
        <Container style={{ paddingTop: "20px", width: "100%" }}>
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="error"
              variant="filled"
            >
              {error}
            </Alert>
          </Snackbar>
          <Snackbar
            open={!!success}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              variant="filled"
            >
              {success}
            </Alert>
          </Snackbar>
          <Box mb={2}>
            <Button
              onClick={() => navigate("/flight-auths")}
              startIcon={<ArrowBackIcon />}
              style={{ border: "none", textTransform: "none", color: "black" }}
            >
              Back to Flight Authorizations
            </Button>
          </Box>
          <Typography variant="h4" paddingBottom={2} gutterBottom>
            Create Flight Authorization Request
          </Typography>
          <Paper
            elevation={2}
            style={{ padding: "20px", marginBottom: "60px", width: "100%" }}
          >
            <Typography variant="h6" paddingBottom={2} gutterBottom>
              Flight Authorization Information
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    name="shortName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Short Name"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Category"
                        fullWidth
                        required
                        variant="outlined"
                      >
                        <MenuItem value="OPEN_CATEGORY_A1">
                          Open Category - A1
                        </MenuItem>
                        <MenuItem value="OPEN_CATEGORY_A2">
                          Open Category - A2
                        </MenuItem>
                        <MenuItem value="OPEN_CATEGORY_A3">
                          Open Category - A3
                        </MenuItem>
                        <MenuItem value="SPECIFIC_CATEGORY">
                          Specific Category
                        </MenuItem>
                        <MenuItem value="CERTIFIED_CATEGORY">
                          Certified Category
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="client"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} label="Client" fullWidth required />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="timeStart"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Start Time"
                        type="datetime-local"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="timeEnd"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="End Time"
                        type="datetime-local"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="pilot"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Pilot Name"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="pilotId"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Pilot ID"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="drone"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Drone Name"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="droneRemoteId"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Drone Remote ID"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="droneModel"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Drone Model"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="droneWeight"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Drone Weight (g)"
                        type="number"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="missionDescription"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Mission Description"
                        fullWidth
                        multiline
                        rows={4}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    variant="outlined"
                    component="label"
                    style={{ marginRight: "10px" }}
                  >
                    Upload QGC Flight Plan
                    <input type="file" hidden onChange={onFileChange} />
                  </Button>
                  {selectedFile && <Typography>{selectedFile.name}</Typography>}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" paddingBottom={0} gutterBottom>
                    Flight Plan Preview
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "0px" }}>
                  <div
                    id="map"
                    style={{ width: "100%", height: "500px" }}
                  ></div>
                </Grid>
                {conflicts.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Conflicting Flight Authorizations
                    </Typography>
                    <List>
                      {conflicts.map((conflict) => (
                        <ListItem
                          key={conflict.id}
                          style={{ backgroundColor: "#ffebee" }}
                        >
                          <ListItemText
                            primary={conflict.shortName}
                            secondary={`ID: ${conflict.id}\nStart Time: ${convertToUserTimezone(conflict.timeStart)} End Time: ${convertToUserTimezone(conflict.timeEnd)}`}
                            secondaryTypographyProps={{
                              style: { whiteSpace: "pre-line" },
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" gap={2}>
                    <Button
                      onClick={() => navigate("/flight-auths")}
                      style={{
                        border: "none",
                        textTransform: "none",
                        color: "black",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      Create Authorization
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      {/* </RequirePermission> */}
    </LoggedInPageLayout>
  );
};
