import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridDeleteIcon,
  GridRowsProp,
} from '@mui/x-data-grid';
import {
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Button,
  Box,
  Container,
  IconButton,
  Tooltip,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { LoggedInPageLayout } from 'src/components/page-layout';
import RequirePermission from 'src/authorization/require-permission';
import { AUTHORIZATION_FLIGHT_AUTH_CREATE, AUTHORIZATION_FLIGHT_AUTH_READ, AUTHORIZATION_GEOZONES_READ } from 'src/authorization/auth-types';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Config } from 'src/config';

interface FlightAuthorization {
  id: string;
  status: string;
  shortName: string;
  timeStart: string;
  timeEnd: string;
  pilot: string;
  drone: string;
}


export const FlightAuthorizationPage: React.FC = () => {
  const [flightAuths, setFlightAuths] = useState<FlightAuthorization[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate(); // Initialize navigate

  const handleDelete = async (id: string) => {
    const accessToken = await getAccessTokenSilently();
    try {
      const response = await fetch(Config.api_core_url + `/flight-auths/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setFlightAuths((prev) => prev.filter((auth) => auth.id !== id));
    } catch (err) {
      setError('Failed to delete flight authorization.');
    }
  };

  const fetchFlightAuths = async () => {
    setLoading(true);
    setError(null);
    const accessToken = await getAccessTokenSilently();
    try {
      const response = await fetch(Config.api_core_url + '/flight-auths', {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setFlightAuths(data);
    } catch (err) {
      setError('Failed to load flight authorizations.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFlightAuths();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id'
    },
    {
      field: 'shortName',
      headerName: 'Name',
      width: 340,
      renderCell: (params) => (
        <Box>
          <Typography variant="body1">{params.row.shortName}</Typography>
          <Typography variant="body2" color="textSecondary">
            {params.row.id}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'timeStart',
      headerName: 'Start Time',
      width: 130,
      renderCell: (params) => (
        <Box>
          <Typography variant="body2">{new Date(params.value).toLocaleDateString()}</Typography>
          <Typography variant="body2">
            {new Date(params.value).toLocaleTimeString()}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'timeEnd',
      headerName: 'End Time',
      width: 130,
      renderCell: (params) => (
        <Box>
          <Typography variant="body2">{new Date(params.value).toLocaleDateString()}</Typography>
          <Typography variant="body2">
            {new Date(params.value).toLocaleTimeString()}
          </Typography>
        </Box>
      ),
    },
    { field: 'pilot', headerName: 'Pilot', width: 150 },
    { field: 'drone', headerName: 'Drone', width: 200 },
    { field: 'status', headerName: 'Status', width: 100 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 60,
      renderCell: (params) => (
        <Tooltip title="Delete">
          <IconButton
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
          >
            <GridDeleteIcon color='action'/>
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const rows: GridRowsProp = flightAuths.map((auth) => ({
    id: auth.id,
    shortName: auth.shortName,
    timeStart: auth.timeStart,
    timeEnd: auth.timeEnd,
    pilot: auth.pilot,
    drone: auth.drone,
    status: auth.status,
  }));

  return (
    <LoggedInPageLayout>
      <RequirePermission
        to={AUTHORIZATION_FLIGHT_AUTH_READ}
        fallback={<div>Unauthorized!</div>}
      >
        <Container style={{ padding: '0px' }}>
          <Box>
            <Paper style={{
              flex: 1,
              width: "calc(100vw - 250px)",
              height: "calc(100vh - 52px)",
            }} >
              <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
                <Typography variant="h4">Flight Authorizations</Typography>
                <Box display="flex" gap={2}>
                  <RequirePermission
                    to={AUTHORIZATION_FLIGHT_AUTH_CREATE}
                    fallback={<div>Unauthorized!</div>}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => navigate('/flight-auths/create-flight-auths')} // Navigate to create page
                    >
                      Create Authorization
                    </Button>
                  </RequirePermission>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<RefreshIcon />}
                  onClick={fetchFlightAuths}
                  disabled={loading}
                >
                  Refresh
                </Button>
                </Box>
              </Box>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="80%">
                  <CircularProgress />
                </Box>
              ) : error ? (
                <Alert severity="error">{error}</Alert>
              ) : (
                <Box padding="10px" style={{
                  flex: 1,
                  width: "calc(100vw - 250px)",
                  height: "calc(100vh - 130px)",
                }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                      },
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns status and traderName, the other columns will remain visible
                          id: false
                        },
                      },
                    }}
                    pageSizeOptions={[10, 15, 30]}
                    disableRowSelectionOnClick
                  />
                </Box>
              )}
            </Paper>
          </Box>
        </Container>
      </RequirePermission>
    </LoggedInPageLayout>
  );
};
